import "./approval.css";
import { Toolbar, Box, Typography, CircularProgress, Pagination, Tabs, Tab, InputBase, Paper, IconButton } from "@mui/material";
import { STRINGS } from "../../utils/Enums";
import TableContainer from "@mui/material/TableContainer";
import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import apiClient from '../../utils/apiClient.js'
import refreshIcon from "../../assets/Images/refresh_icon.png";
import Empty_Icon from "../../assets/Images/empty.png";
import SearchIcon from '@mui/icons-material/Search';
import profilePic from "../../assets/Images/boxThreeImg.png";
import { Link } from "react-router-dom";
import VisibilityIcon from '@mui/icons-material/Visibility';


const Approval = () => {
  const [flag, setFlag] = useState("");
  const [loading, setLoading] = useState(false);
  const [paymentData, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [tab, setTab] = useState('pending');

  useEffect(() => {
    getListing(page, tab, searchTerm);
  }, [page, tab, searchTerm]);

  const getListing = async (pageNumber, status, searchTerm, clickFlag = '') => {
    try {
      setLoading(true);
      const data = {
        limit: 10,
        pageNumber,
        status,
        searchTerm,
        role: (clickFlag ? clickFlag : flag).toUpperCase()
      };
      const response = await apiClient.post(`public/user/user-listing`, data);
      setData(response?.data?.data?.list || []);
      setTotalPages(response?.data?.pagination?.totalPage || 1);
      setLoading(false);
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
    setPage(1);
  };

  const handleFlagChange = (event) => {
    setFlag(event);
    setPage(1);
    getListing(page, tab, searchTerm, event);
  };

  const handleSearchChange = (event) => {
    setPage(1);
    setSearchTerm(event.target.value);
  };

  const requestRes = async (id, status) => {
    try {
      const data = {
        status,
      };
      await apiClient.post(
        `public/user/profile-status/${id}`,
        data
      );
      getListing(page, tab, searchTerm);
    } catch (error) {
      console.log("error", error);
    }
  };


  return (
    <>
      {flag === "" ? (
        <>
          <div className="mainPageWrapper">
            <div className="mainWrapper">
              <div className="selectOne">
                <p>SELECT ONE</p>
              </div>
              <div className="btnFlex">
                <button
                  onClick={() => {
                    handleFlagChange('concept')
                  }}
                  className="btnStyle"
                >
                  CONCEPT{" "}
                </button>
                <button
                  onClick={() => {
                    handleFlagChange("influencer");
                  }}
                  className="btnStyle"
                >
                  INFLUENCER{" "}
                </button>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div
            style={{
              width: "98%",
              border: "3px solid black",
              margin: "0px 10px 10px 10px",
              borderRadius: "15px",
              padding: "0px 10px",
            }}
          >
            <Toolbar style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <p
                style={{
                  fontFamily: STRINGS.FONTS.HELVITICA,
                  fontSize: "18px",
                  fontWeight: "700",
                  margin: "0px",
                }}
              >
                {flag.toUpperCase() + ' APPROVAL'}
              </p>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Paper
                  component="form"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    width: 250,
                    padding: '2px 4px',
                    marginRight: '10px',
                  }}
                >
                  <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Search"
                    inputProps={{ 'aria-label': 'search' }}
                    onChange={handleSearchChange}
                    value={searchTerm}
                  />
                  <SearchIcon />
                </Paper>
              </div>
            </Toolbar>

            <Tabs value={tab} onChange={handleTabChange} centered>
              <Tab label="Pending" value="pending" />
              <Tab label="Accept" value="accept" />
              <Tab label="Deny" value="deny" />
            </Tabs>

            {loading ? (
              <Box display="flex" justifyContent="center" alignItems="center" minHeight="50vh">
                <CircularProgress />
              </Box>
            ) : (
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        style={{ fontFamily: STRINGS.FONTS.HELVITICA, fontWeight: "700", fontSize: "10px" }}
                        align="center"
                      >
                        User
                      </TableCell>
                      <TableCell
                        style={{ fontFamily: STRINGS.FONTS.HELVITICA, fontWeight: "700", fontSize: "10px" }}
                        align="center"
                      >
                        Contact No
                      </TableCell>
                      {/* <TableCell
                        style={{ fontFamily: STRINGS.FONTS.HELVITICA, fontWeight: "700", fontSize: "10px" }}
                        align="center"
                      >
                        Country
                      </TableCell> */}
                      <TableCell
                        style={{ fontFamily: STRINGS.FONTS.HELVITICA, fontWeight: "700", fontSize: "10px" }}
                        align="center"
                      >

                      </TableCell>
                      <TableCell
                        style={{ fontFamily: STRINGS.FONTS.HELVITICA, fontWeight: "700", fontSize: "10px" }}
                        align="center"
                      >

                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {paymentData.length === 0 ? (
                      <TableRow>
                        <TableCell colSpan={7} align="center">
                          <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center" gap="20px">
                            <Box className="icon_" width="170px">
                              <img src={Empty_Icon} alt="No Data" width="100%" />
                            </Box>
                            <Typography fontFamily={STRINGS.FONTS.HELVITICA} fontWeight="700" fontSize="16px">
                              No Data Found
                            </Typography>
                            <Box display="flex" gap="20px" alignItems="center" onClick={() => getListing(page, tab, searchTerm)} style={{ cursor: "pointer" }}>
                              <Typography fontFamily={STRINGS.FONTS.HELVITICA} fontWeight="700" fontSize="16px" margin="0">
                                Retry
                              </Typography>
                              <img src={refreshIcon} alt="Retry" width="20px" />
                            </Box>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ) : (
                      paymentData.map((element) => (
                        <TableRow key={element?._id}>
                          <TableCell align="center">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                gap: "10px",
                              }}
                            >
                              <img
                                style={{
                                  height: "40px",
                                  width: "40px",
                                  borderRadius: "10px",
                                }}
                                src={`${element?.img ? element?.img : profilePic}`}
                              // src={profilePic}
                              />
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  width: "150px",
                                }}
                              >
                                <p style={{ margin: "0px", fontWeight: "700" }}>
                                  {element?.name}
                                </p>
                                <p style={{ margin: "0px" }}>
                                  {element?.email}
                                </p>
                              </div>
                            </div>
                          </TableCell>
                          <TableCell align="center">{element?.phone}</TableCell>
                          {/* <TableCell align="center">{element?.country}</TableCell> */}
                          <TableCell align="center">
                            <div
                              style={{
                                display: "flex",
                                gap: "10px",
                                justifyContent: "center",
                              }}
                            >
                              {tab != 'accept' ? <button
                                style={{
                                  backgroundColor: "#A9FF74",
                                  border: "none",
                                  height: "40px",
                                  width: "90px",
                                  borderRadius: "6px",
                                  fontFamily: STRINGS.FONTS.HELVITICA,
                                  fontSize: "10px",
                                  fontWeight: "700",
                                }}
                                onClick={() =>
                                  requestRes(
                                    element?.userId,
                                    'ACCEPT'
                                  )
                                }
                              >
                                Accept
                              </button> : ''}
                              {tab == 'pending' ? <button
                                style={{
                                  backgroundColor: "#FF0000",
                                  border: "none",
                                  height: "40px",
                                  width: "90px",
                                  borderRadius: "6px",
                                  fontFamily: STRINGS.FONTS.HELVITICA,
                                  fontSize: "10px",
                                  fontWeight: "700",
                                }}
                                onClick={() =>
                                  requestRes(
                                    element?.userId,
                                    'REJECT'
                                  )
                                }
                              >
                                DECLINE
                              </button> : ''}
                            </div>
                          </TableCell>
                          <TableCell
                            style={{ fontFamily: STRINGS.FONTS.HELVITICA, fontWeight: "700", fontSize: "10px" }}
                            align="center"
                          >
                            <Link
                              style={{
                                fontFamily: STRINGS.FONTS.HELVITICA,
                                fontWeight: '700',
                                fontSize: '14px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                              }}
                              to={`${flag == 'concept' ? STRINGS.ROUTES.CONCEPT_VIEW : STRINGS.ROUTES.INFLUENCER_VIEW}/${element?._id}`}
                            >
                              <IconButton>
                                <VisibilityIcon style={{ fontSize: '18px' }} />
                              </IconButton>
                              {/* View */}
                            </Link>
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
            <Box display="flex" justifyContent="center" margin="20px 0">
              <Pagination count={totalPages} page={page} onChange={handlePageChange} />
            </Box>
          </div>
        </>
      )}
    </>
  );
};

export default Approval;
