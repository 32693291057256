import axios from "axios";
import { STRINGS } from "./Enums";
import store from "../redux/store";

const apiClient = axios.create({
    baseURL: STRINGS.apiUrl, // Use environment variables for flexibility
    timeout: 10000,
});


// Add a request interceptor for adding the token
apiClient.interceptors.request.use(
    (config) => {
        const state = store.getState(); // Get the latest state from Redux store
        const token = state.token.token; 
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        // Handle request errors
        return Promise.reject(error);
    }
);

// Optionally, add a response interceptor to handle global errors (e.g., 401 Unauthorized)
apiClient.interceptors.response.use(
    (response) => response,
    (error) => {
        // alert(error.response?.status)
        if (error.response?.status === 401) {
            // Handle token expiration or unauthorized errors
            console.error('Unauthorized, redirecting to login...');
            // Redirect to login page or refresh token logic
        }
        return Promise.reject(error);
    }
);

export default apiClient;
